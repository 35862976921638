import React from 'react';
import './Spinner.css';

export default function Spinner(props) {
  const spinnerColor = props.spinnerColor || 'blue';
  const ringColor = props.ringColor || 'rgba(0, 0, 0, 0.1)';

  const spinnerStyle = {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    border: `4px solid ${ringColor}`,
    borderLeftColor: spinnerColor
  };

  return (
    <div>
        <div className="spinner" style={spinnerStyle}></div>
    </div>
  );
}
